import Vue from 'vue'

import '@/plugins/axios'
import vuetify from '@/plugins/vuetify';
import nprogress from '@/plugins/vue-nprogress';
import store from '@/store'
import router from '@/router'

import '@/plugins/filter.js'
import '@/mixins/main';

import '@/registerServiceWorker'

import App from './App.vue'
import ScannerArea from '@/components/helper/ScannerArea';
Vue.component('scanner-area', ScannerArea);

new Vue({
  vuetify,
  nprogress,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
