import Vue from 'vue'
//import sound_ok from '@/assets/sounds/primary/navigation_forward-selection.ogg';
//import sound_beep from '@/assets/sounds/primary/ui_tap-variant-04.ogg';
//import sound_error from '@/assets/sounds/secondary/alert_error-02.ogg';
//import sound_desaster from '@/assets/sounds/secondary/ui_loading.ogg';
//import sound_finish from '@/assets/sounds/hero/hero_simple-celebration-01.ogg';

import sound_ok from '@/assets/sounds/ok.wav';
import sound_beep from '@/assets/sounds/beep.wav';
import sound_error from '@/assets/sounds/error.mp3';
import sound_desaster from '@/assets/sounds/desaster.wav';
import sound_finish from '@/assets/sounds/finish.wav';

Vue.mixin({
    data() {
        return {
            validation_rules: {
                required: [
                    v => !!v || 'Pflichtfeld',
                ],
            },
            state_map: {
                inbound: {
                    gathered: {
                        icon: 'create',
                        color: 'blue',
                    },
                    unload_in_progress: {
                        icon: 'loop',
                        color: 'blue'
                    },
                    unloaded: {
                        icon: 'fiber_manual_record',
                        color: 'indigo'
                    },
                    store_in_progress: {
                        icon: 'settings_backup_restore',
                        color: 'lime'
                    },
                    done: {
                        icon: 'home',
                        color: 'green'
                    },
                },
                outbound: {
                    gathered: {
                        icon: 'create',
                        color: 'blue'
                    },
                    provision_in_progress: {
                        icon: 'loop',
                        color: 'blue'
                    },
                    provisioned: {
                        icon: 'fiber_manual_record',
                        color: 'indigo'
                    },
                    loading_in_progress: {
                        icon: 'settings_backup_restore',
                        color: 'lime'
                    },
                    unloading_in_progress: {
                        icon: 'settings_backup_restore',
                        color: 'lime'
                    },
                    commission_in_progress: {
                        icon: 'layers',
                        color: 'pink'
                    },
                    commissioned: {
                        icon: 'layers',
                        color: 'pink'
                    },
                    transfer: {
                        icon: 'directions_bus',
                        color: 'indigo'
                    },
                    done: {
                        icon: 'home',
                        color: 'green'
                    },
                },
                samples: {
                    pending: {
                        icon: 'fiber_manual_record',
                        color: 'blue',
                    },
                    done: {
                        icon: 'fiber_manual_record',
                        color: 'green',
                    },
                },
                storage_unit: {
                    pending: {
                        icon: 'create',
                        color: 'blue',
                    },
                    unloaded: {
                        icon: 'call_missed',
                        color: 'pink',
                    },
                    stored: {
                        icon: 'room',
                        color: 'green',
                    },
                    transfer: {
                        icon: 'swap_horiz',
                        color: 'orange',
                    },
                    commission: {
                        icon: 'layers',
                        color: 'light-green',
                    },
                    commission_used_as_source: {
                        icon: 'layers',
                        color: 'yellow'
                    },
                    provision_for_commission: {
                        icon: 'layers',
                        color: 'pink'
                    },
                    provisioned: {
                        icon: 'call_missed_outgoing',
                        color: 'lime',
                    },
                    moved_out: {
                        icon: 'send',
                        color: 'red',
                    },
                    deleted: {
                        icon: 'delete',
                        color: 'red',
                    },
                    missing: {
                        icon: 'help_outline',
                        color: 'pink',
                    },
                    missing_deleted: {
                        icon: 'help_outline',
                        color: 'red',
                    },
                    commission_remaining: {
                        icon: 'fiber_manual_record',
                        color: 'lime',
                    },
                    hold: {
                        icon: 'block',
                        color: 'amber',
                    },
                },
            },
        }
    },
    methods: {
        getIconByState(type, state) {
            if (this.state_map[type]) {
                if (this.state_map[type][state]) {
                    return this.state_map[type][state].icon;
                }
            }
            return '';
        },
        getColorByState(type, state) {
            if (this.state_map[type]) {
                if (this.state_map[type][state]) {
                    return this.state_map[type][state].color;
                }
            }
            return '';
        },
        playSound(type) {

            let sound;
            switch (type) {
                case "error":
                    sound = sound_error;
                    break;
                case "warn":
                    sound = sound_beep;
                    break;
                case "success":
                    sound = sound_ok;
                    break;
                case "finish":
                    sound = sound_finish;
                    break;
                case 'desaster':
                    sound = sound_desaster;
                    break;
                default:
                    new Error("no valid sound to play");
                    break;
            }
            if (sound) {
                let audio = new Audio(sound);
                audio.play();
            }
        }
    }
});