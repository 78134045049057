"use strict";

import Vue from 'vue';
import axios from "axios";
import store from "@/store";
//import sound_error from '@/assets/sounds/secondary/alert_error-02.ogg';
import sound_error from "@/assets/sounds/error.mp3";

let config = {
    baseURL: process.env.VUE_APP_APIURL || "",
    timeout: 60 * 1000,
    withCredentials: true,
    credentials: "same-origin",
    params: {
        "access_origin": window.location.origin
    }
};

const _axios = axios.create(config);
const _axios_debug = axios.create(config);

const _intranet = axios.create({
    baseURL: process.env.VUE_APP_INTRANETURL || "",
    withCredentials: true,
    params: {},
    paramsSerializer: function (params) {
        if (params.filter) {
            // CLEAN EMPTY VALUES
            params.filter = Object.keys(params.filter)
                .filter(e => params.filter[e] !== null)
                .reduce((o, e) => {
                    o[e] = params.filter[e]
                    return o;
                }, {});
        }
        var qs = require('qs');
        return qs.stringify(params, {encode: false, arrayFormat: 'comma'});
    },
});

_axios.interceptors.request.use(
    function (config) {
        if(store.state.auth.warehouse){
            config.params.warehouse_id = parseInt(localStorage.getItem('warehouse'));
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

_intranet.interceptors.request.use(
    function (config) {
        if(store.state.auth.warehouse){
            config.params.warehouse_id = parseInt(localStorage.getItem('warehouse'));
        }
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);

let handleOk = function (response) {
    if (response && response.data && response.data.message) {
        store.commit("addMessage", {
            type: response.data.message_type ? response.data.message_type : "error",
            message: response.data.message,
        });
    }
    return response;
}

let handleException = function (error) {
    let audio = new Audio(sound_error);
    audio.play();

    if (error.response) {
        if (error.response.status === 401) {
            store.commit("addMessage", {
                type: "error",
                message: 'Zugriff verweigert ( WMS ) msg: ' + error.response.data.message
            });
        }else if(error.response.data && error.response.data.message){
            store.commit("addMessage", {
                type: "error",
                message: error.response.data.message
            });
        }else {
            store.commit("addMessage", {
                type: "error",
                message: "Unbekannter Fehler"
            });
        }
    }
    return Promise.reject(error);
}

let handleExceptionIntranet = function (error) {
    let audio = new Audio(sound_error);
    audio.play();

    if (error.response) {
        if (error.response.status === 401) {
            store.commit("addMessage", {
                type: "error",
                message: 'Zugriff verweigert ( intranet )'
            });
        }else if(error.response.data && error.response.data.message){
            store.commit("addMessage", {
                type: "error",
                message: error.response.data.message
            });
        }else {
            store.commit("addMessage", {
                type: "error",
                message: "Unbekannter Fehler"
            });
        }
    }
    return Promise.reject(error);
}

// Add a response interceptor
_axios.interceptors.response.use(
    handleOk,
    handleException
);

_intranet.interceptors.response.use(
    handleOk,
    handleExceptionIntranet
);

Plugin.install = function (Vue) {
    Vue.axios = _axios;
    Vue.intranet = _intranet;
    Vue.axios_debug = _axios_debug;

    window.axios = _axios;
    window.intranet = _intranet;
    window.axios_debug = _axios_debug;

    Object.defineProperties(Vue.prototype, {
        axiosDebug: {
            get() {
                return _axios_debug;
            }
        },
        axios: {
            get() {
                return _axios;
            }
        },
        $axios: {
            get() {
                return _axios;
            }
        },
        intranet: {
            get() {
                return _intranet;
            }
        },
        $intranet: {
            get() {
                return _intranet;
            }
        },
    });
};

Vue.use(Plugin)

export default Plugin;
