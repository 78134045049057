import Vue from 'vue';
import {getField, updateField} from 'vuex-map-fields';

const state = {
    // CACHAED ORDERS
    orders: [],

    trailer: null,

    // ON DETAIL SCOPE
    active_storage_unit: null,

    outbound: {
        delivery_note_number: null,
        custom_field_1: null,
    },
    storage_units: [],
    client: {
        name: null,
        id: null,
    },

};

const getters = {
    getField,

    allowAlternativies(state) {
        if (state.client) {
            if (state.client.allow_alternatives == "1" || state.client.allow_alternatives === true) {
                return true;
            }
        }
        return false;
    },
    loaded: (state) => {
        if (state.outbound) {
            if (state.outbound.id !== null) {
                return true;
            }
        }
        return false;
    }
};


const actions = {
    unloadActiveStorageUnit({commit, state, rootState}) {
        const request = {
            id: state.active_storage_unit.id,
            inbound_id: state.outbound.id,
            action: "unload_transfer",
            gate_id: rootState.gate.id,
        };

        return Vue.axios.put("storage-unit", request).then(function () {
            commit('updateActiveStorageUnitState', "unloaded");
        });
    },
    storeActiveStorageUnit({commit, state}) {
        const request = {
            id: state.active_storage_unit.id,
            space: state.active_storage_unit.space,
            action: "store",
        };

        return Vue.axios.put("storage-unit", request).then(function () {
            commit('updateActiveStorageUnitState', "stored");
            commit('resetActiveStorageUnit');
        });
    },
    create({commit, state, rootState}) {
        // CHECK IF OUTBOUND EXISTS
        const request = {
            action: 'create_transfer',
            client_id: state.client.id,
            delivery_note_number: state.outbound.delivery_note_number,
            custom_field_1: state.outbound.custom_field_1,
            gate_id: rootState.gate.id,
            warehouse_id: state.warehouse.id
        };

        return Vue.axios.post("outbound", request).then(function (response) {
            commit('updateOutboundId', response.data.id);
            commit('updateOrderNumber', response.data.order_number);
            commit('setClient', response.data.client);
        });
    },
    load({commit, state}) {

        const request = {
            id: state.outbound.id,
            action: 'load_transfer',
            licence_plate: state.trailer,
        };

        return Vue.axios.put("transfer", request).then(function () {
            commit('reset');
        });

    },

    addStorageUnitToOutbound({commit, state}, payload) {
        const request = {
            action: 'add_storage_unit',
            outbound_id: state.outbound.id,
            storage_unit: payload,
        };
        return Vue.axios.put("outbound", request).then(function (response) {
            if (response.data.storage_unit) {
                commit('addStorageUnitToOutbound', response.data.storage_unit);
            }
        })
    },
    revert({commit, state}) {
        const request = {
            action: 'transfer_revert',
            outbound_id: state.outbound.id,
        };
        return Vue.axios.put("outbound", request).then(function () {
            commit('reset');
        });
    },
    updateOutboundMeta({state}) {
        const request = {
            action: 'outbound_meta',
            delivery_note_number: state.outbound.delivery_note_number,
            custom_field_1: state.outbound.custom_field_1,
            licence_plate: state.trailer,
            outbound_id: state.outbound.id,
        };
        return Vue.axios.put("outbound", request);
    },
    updateCurrent({commit}, payload) {
        commit('updateCurrent', payload);
    },
    provisionActiveStorageUnit({state, commit, rootState}) {
        const request = {
            id: state.active_storage_unit.id,
            outbound_id: state.outbound.id,
            action: "provision_gate",
            gate_id: rootState.gate.id,
        };

        return Vue.axios.put("storage-unit", request).then(function () {
            commit('updateActiveStorageUnitState', "provisioned");
        });
    },
    loadActiveStorageUnit({state, commit, rootState}) {
        const request = {
            id: state.active_storage_unit.id,
            outbound_id: state.outbound.id,
            action: "transfer",
            trailer: state.trailer,
            gate_id: rootState.gate.id,
        };

        return Vue.axios.put("storage-unit", request).then(function () {
            commit('updateActiveStorageUnitState', "transfer");
        });
    },
    setActiveStorageUnit({commit}, payload) {
        commit('setActiveStorageUnit', payload);
    },
};

const mutations = {
    updateField,
    updateOutboundId(state, payload) {
        state.outbound.id = payload;
    },
    updateOrderNumber(state, payload) {
        state.outbound.order_number = payload;
    },
    setClient(state, payload) {
        state.client = payload;
    },
    setTrailer(state, payload) {
        state.trailer = payload;
    },
    updateCurrent(state, payload) {
        Vue.set(state, 'outbound', payload.outbound);
        Vue.set(state, 'client', payload.client);
        Vue.set(state, 'storage_units', payload.storage_units);
    },
    updateActiveStorageUnitState(state, payload) {
        Vue.set(state.active_storage_unit, 'state', payload);
    },
    setActiveStorageUnit(state, nve) {
        Vue.set(state, 'active_storage_unit', state.storage_units.find(item => item.nve === nve))
    },
    resetActiveStorageUnit(state) {
        Vue.set(state, 'active_storage_unit', null);
    },
    reset(state) {
        Vue.set(state, 'outbound', {
            delivery_note_number: null,
            custom_field_1: null,
        });
        Vue.set(state, 'storage_units', []);
    },
    updateActiveStorageUnitSpace(state, payload) {
        Vue.set(state.active_storage_unit, 'space', payload);
    },
    addStorageUnitToOutbound(state, payload) {
        state.storage_units.push(payload);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
}
