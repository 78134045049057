<template>
    <v-bottom-navigation
            style="border-top:1px solid white;"
            :app="true"
            :value="show"
            fixed
            height="45"
    >
        <v-text-field
            type="search"
            :clearable="true"
            :flat="true"
            @keyup.enter="submit"
            id="scanner_area"
            ref="scanner_area"
            solo
            v-model="value"
            autocomplete="off"
        />
    </v-bottom-navigation>
</template>
<style>
    #scanner_area {
        margin: 5px;
    }

    .v-bottom-nav {
        border-top: 1px solid white;
        z-index: 1000;
    }
</style>
<script>

    export default {
        name: 'ScannerArea',
        props: {
            expected: {
                type: Array,
                required: false
            },
            clientId: {
                type: [String, Number],
                required: false
            },
            expandNve: {
                type: Boolean,
                required: false,
                default: true
            }
        },
        data: function () {
            return {
                interval: null,
                show: true,
                value: '',
            }
        },
        created() {
            this.interval = setInterval(this.focusChanged, 500);
        },
        beforeDestroy() {
            clearInterval(this.interval);
        },
        mounted() {
            this.$refs.scanner_area.focus();
        },
        methods: {
            focusChanged() {
                if (
                    document.activeElement.tagName !== "INPUT" &&
                    document.activeElement.tagName !== "TEXTAREA" &&
                    document.activeElement.tagName !== "SELECT"
                ) {
                    this.$refs.scanner_area.focus();
                    this.show = true;
                } else {
                    if (document.activeElement.id !== "scanner_area") {
                        this.show = false;
                        this.value = "";
                    }
                }
            },
            submit() {
                let _return = {};

                let vm = this;
                _return.value = this.value;
                var re = new RegExp("(.*)-(.*)-(.*)");
                var re_trailer = new RegExp("(.*)-(.*)-(.*)-(.*)");

                // SUCKS nve is unique customer number, could have different lengths
                if (
                    this.value.length == 20 ||
                    (this.value.length == 9 && this.clientId == "200135") ||
                    (this.value.length == 10 && this.clientId == "1000003")

                ) {
                    // AXIOS REQUEST TO CHECK IF IT IS A NVE?!
                    // GET ALL CONFIGURED BATCH PATTERN AND CHECK AGAINST?
                    _return.type = "nve";
                } else if (this.value.startsWith("EIN-")) {
                    _return.type = "inbound";
                } else if (this.value.startsWith("AUS-")) {
                    _return.type = "outbound";
                } else if (this.value.startsWith("UM-")) {
                    _return.type = "transfer";
                } else if (re.test(this.value)) {
                    _return.type = "space";
                } else if (re_trailer.test(this.value)) {
                    _return.type = "space";
                } else if (!_return.type) {
                    _return.type = "unknown"
                }

                this.value = "";
                if (this.expected && !this.expected.includes(_return.type)) {
                    vm.$store.commit("addMessage", {
                        type: "error",
                        message: "Ungültiger scan",
                    });
                    return;
                }
                this.$emit('submit', _return)
            },
        },
    };
</script>